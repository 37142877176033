@import url('https://fonts.googleapis.com/css2?family=Merriweather&family=Outfit:wght@200;300;400;500;600;700;800&family=Poppins:wght@400;500;600;700&display=swap');
:root{
  --primary: #1f3e72;
  --secondary: rgba(225,225,225, 0.78);
  --black:#131110;
  --blue-gradient: linier-gradient(97.05deg, #4066ff 3.76%, #2949c6 100%);
  --orange-gradient: linier-gradient(270deg, #ffb978 3.76%, #ff922d 100%);
  --blue: #4066ff;
  --lightBlur: #eeeeff;
  --shadow: 0px 23px 21px -8px rgba(136, 160, 255, 0.25);
}

*{
 
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}
a{
  color: inherit;
  text-decoration: none;
}
.paddings{
  padding: 1.5rem;
}
.innerWidth{
  width: 100%;
}
.flexCenter{
  display: flex;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.flexStart{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flexEnd{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flexColCenter{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}
.flexColStart{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.flexColEnd{
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-end;
}
.primaryText{
  color: #1f3e72;
  font-weight: bold;
  font-size: 2rem;
}
.secondaryText{
  color: rgb(140, 139, 139);
  font-size: 0.9rem;
}
.orangeText{
  color: orange;
  font-size: 1.5rem;
  font-weight: 600;
}

.button{
  font-weight: 500;
  padding: 0.6rem 1.4rem;
  color: white;
  /* background: blue; */
  background: var(--blue);
  border: none;
  border-radius: 4px;
  transition: all 300ms ease-in;
}
.button:hover{
  cursor: pointer;
  transform: scale(1.1);
}

/* Media Quary */
@media (min-width: 1536px){
  .innerWidth{
    max-width: 1280px;
    margin: auto;
  }
}

@media (min-width: 640px){
  .paddings{
    padding: 4rem;
  }
}

@media (min-width: 1280px), (min-width: 768px){
  .paddings{
    padding: 2rem;
  }
}

@media (min-width: 640px){
  .primaryText{
    font-size: 1.5rem;
  }
  .orangeText{
    font-size: 1.2rem;
  }
}


