.App{
  position: relative;
  overflow-x: clip;
}
.backgroundBlur{
  background-color: black;
  position: relative;

}
.white-gradiant{
  position: absolute;
  width: 20rem;
  height: 20rem;
  background-color: rgb(66, 65, 65);
  filter: blur(100px);
  border-radius: 100px;
}



