.h-wrapper{
    /* background: var(--black); */
    color: white;
}

.h-container{
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: var(--secondary);
}
.h-menu{
    gap: 2rem;
}
.h-menu>*:hover{
    cursor: pointer;
   
}
/* .nav>a{
    color: white; 
} */

/* responcive */
.menu-icon{
    display: none;

}
@media  (max-width:768px){
    .menu-icon{
        display: block;
    }
    .h-menu{
        z-index: 99;
        color: black !important;;
        position: absolute;
        top: 3rem;
        right: 5rem;
        background: rgb(255, 255, 255);
        flex-direction: column;
        font-weight: 500;
        gap: 2rem;
        padding: 2rem;
        border-radius: 12px;
        align-items: flex-start;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 1);
        transition: all 300ms ease-in;
    }
}


