.c-container{
    justify-content: space-between;
}
 .c-container{
    flex: 1;

}
/* .c-right{
    width: 100%;

} */
.c-left{
    gap: 0.5rem;
} 
.contactModes{
    margin-top: 2rem;
    gap: 1rem;
}
.contactModes .row{
    gap: 1.5rem;

}
.mode{
    width: 16rem;
    padding: 1rem;
    border: 0.5px solid orange ;
    border-radius: 1rem;
    gap: 1rem;
    transition: all 300ms ease-in;
}
.button{
    margin-left: 1rem;
}
.mode .button{
    width: 100%;
    
    background: rgb(197, 197, 248) ;
    color: var(--blue);
    font-size: 0.9rem;
    font-weight: 600;
}
.mode>:nth-child(1){
    width: 100%;
    gap: 1.6rem;
}
.mode .detail .primaryText{
    font-size: 1.1rem;
    font-weight: 600;
}
.mode:hover{
    scale: 1.1;
    box-shadow: var(--shadow);
}
.mode .button:hover{
    background: var(--blue);
    color: white;
    scale: 0.8;
}
.button{
    padding-left: 2rem;
}
.detail .secondaryText{
    font-size: 0.7rem;
}
@media(max-width:1024px){
    .c-container{
        flex-direction: column;
    }
    .c-right{
        justify-content: center;
    }
    .contactModes{
        width: 100%;
    }
    .row{
        flex-direction: column;
        width: 100%;
    }
    .mode{
        width: 90%;
    }
}